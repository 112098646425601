import React, { useState, useEffect, useRef } from "react";
import BASE_URLPaths from "../configBaseURL";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { ConfirmDialog } from 'primereact/confirmdialog'; // For <ConfirmDialog /> component
import { confirmDialog } from 'primereact/confirmdialog'; // For confirmDialog method
import { Toast } from 'primereact/toast';

export default function LocationFolderList({
  onupdateformData,
  refreshKey,
  ondeleteformData,
}) {
  const [folderlist, setFolderlist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    LocationName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    FolderName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    OrderByNo: { value: null, matchMode: FilterMatchMode.CONTAINS },
    CreatedDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [visible, setVisible] = useState(false);
  const [selectedrowData, setselectedrowData] = useState([]);
  const [removefoldername, setremovefoldername] = useState(""); // Store the selected value
  const toast = useRef(null);
  const buttonEl = useRef(null);

  const hideDialog = () => {
    setVisible(false);
  };

  const confirmAction = () => {
    // Handle the confirmation action here
    console.log('Confirmed! Value:', removefoldername);
    //toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have removed', life: 5000 });
    ondeleteformData(selectedrowData);
    hideDialog();
  };

  const cancelAction = () => {
    // Handle the cancellation action here
    console.log('Cancelled');
    toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 5000 });
    hideDialog();
  };


  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const handleEditItem = (rowData) => {
    onupdateformData(rowData);
  };
  const handleRemoveItem = (rowData) => {
    setselectedrowData(rowData);
    setremovefoldername(rowData.FolderName);
    setVisible(true);
    //ondeleteformData(rowData);
  };

  const linkTemplate = (rowData) => {
    return (
      <Button
        label={rowData.FolderName}
        link
        onClick={() => handleEditItem(rowData)}
      />
    );
  };

  const DeleteLocationButton = (rowData) => {
    return (
      <a className="pi pi-trash" ref={buttonEl}  style={{textDecoration:"none",cursor:"pointer"}} onClick={() => handleRemoveItem(rowData)} />
    );
  };

  const header = renderHeader();

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
  const paginatorRight = <Button type="button" icon="pi pi-download" text />;

  useEffect(() => {
    GetAllLocationFolders();
  }, []);

  useEffect(() => {
    GetAllLocationFolders();
  }, [refreshKey]);

  const GetAllLocationFolders = () => {
    axios
      .get(`${BASE_URLPaths.BASE_URL}/api/GetAllLocationFolders`)
      .then((response) => {
        setFolderlist(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <div className="card ">
         <Toast ref={toast}  position="top-right" />
         <ConfirmDialog
        visible={visible}
        onHide={hideDialog}
        message={`Do you want to proceed with removal of Folder: ${removefoldername}?`}
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        acceptLabel="Yes"
        rejectLabel="No"
        accept={confirmAction}
        reject={cancelAction}
      />

      <DataTable
        className="fs-6"
        value={folderlist}
        paginator
        rows={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
        tableStyle={{ minWidth: "50rem" }}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        paginatorLeft={paginatorLeft}
        paginatorRight={paginatorRight}
        filters={filters}
        filterDisplay="row"
        loading={loading}
        globalFilterFields={[
          "LocationName",
          "FolderName",
          "OrderByNo",
          "CreatedDate",
        ]}
        header={header}
        emptyMessage="No Location found."
      >
        <Column
          field="FolderName"
          header="Folder Name"
          sortable
          style={{ width: "30%" }}
          body={linkTemplate}
        ></Column>
        <Column
          field="LocationName"
          header="Location Name"
          sortable
          style={{ width: "25%" }}
        ></Column>
        <Column
          field="OrderByNo"
          header="Order By No"
          sortable
          style={{ width: "10%" }}
        ></Column>
        <Column
          field="IsActive"
          header="Is Active"
          sortable
          style={{ width: "10%" }}
        ></Column>
        <Column
          field="CreatedDate"
          header="Created Date"
          sortable
          style={{ width: "20%" }}
        ></Column>
        <Column
          field="FolderID"
          header=""
          
          style={{ width: "5%" }}
          body={DeleteLocationButton}
        ></Column>
      </DataTable>
    </div>
  );
}
