import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import BASE_URLPaths from "../configBaseURL";
import { Accordion, AccordionTab } from "primereact/accordion";
import FolderTiles from "./FolderTiles";
import { AppState } from "../App";
import Loader from "./Loader";

const ProductLocation = () => {
  const userData = useContext(AppState);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  //console.log(userData.username)

  // const GetAllLocations = () => {
  //   axios.get(`${BASE_URLPaths.BASE_URL}/api/GetLocationDetails`).then((response) => {
  //     //console.log(response);
  //     setLocations(response.data);
  //     setLoading(false);
  //   }).catch((err)=>{
  //     console.log(err);
  //   });
  // };

  const userDetail = {
    username: userData.username,
  };

  const GetAllLocations = () => {
    axios
      .post(`${BASE_URLPaths.BASE_URL}/api/GetLocations`, userDetail)
      .then((response) => {
        //console.log(response);
        setLocations(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetAllLocations();
  }, []);

  const panelClassName = (parent, index) => {
    if (parent.state.activeIndex === index) return "bg-primary";
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Accordion activeIndex={0}>
          {locations.map((location, i) => {
            return (
              <AccordionTab
                key={i}
                pt={{
                  headerAction: ({ parent }) => ({
                    className: "panelClassName(parent, i)",
                  }),
                }}
                className="default-font poppins-font"
                header={location.LocationName}
              >
                <p className="m-0 ">
                  <FolderTiles location={location} />
                </p>
              </AccordionTab>
            );
          })}
        </Accordion>
      )}
    </div>
  );
};

export default ProductLocation;
