import * as React from "react";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Link,
  Route,
  Navigate,
} from "react-router-dom";
import { Menubar } from "primereact/menubar";
import { InputText } from "primereact/inputtext";
import ProductLocation from "./ProductLocation";
import ProductFilesCL from "./ProductFilesCL";
import AddFolders from "./AddFolders";
import { Button } from "primereact/button";
import Cookies from "js-cookie";
import "./Default.css";
import Loader from "./Loader";
import Login from "../Login";
import ChangePassword from "./ChangePassword";
import { Dialog } from "primereact/dialog";
import "primeicons/primeicons.css";
  

export default function Navbar({ user, handleLogout }) {
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const items = [
    {
      label: (
        <Link
          to="/"
          className="default-font poppins-font link-no-underline m-6"
        >
          Home
        </Link>
      ),
      icon: "pi pi-fw pi-home",
    },
    {
      label: <Link to="/addFolders" className="link-no-underline" >Add Folders</Link>,
      icon: "pi pi-fw pi-folder",
      visible:user.IsAdmin,
    },
  ];
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 100); // Simulate a 2-second delay
  }, []);

  const start = (
    <Link to="/" className="default-font poppins-font link-no-underline m-6">
      <img
        alt="logo"
        src={`/images/${user.ImageName}`}
        //src="/images/kelvinLogo.png"
        height="40"
        className="mr-2"
      ></img>
    </Link>
  );
  const end = (
    <div className="poppins-font" style={{ display: "flex" }}>
      <span
        style={{
          marginRight: "15px",
          marginTop: "1%",
          textTransform: "uppercase",
          fontWeight: "bold",
        }}
      >
        {user.username}
      </span>
      <div>
        <i
        title="Change Password"
          className="pi pi-lock-open"
          style={{ fontSize: "1.5rem", cursor: "pointer", color: "white",marginRight: "6px" }}
          onClick={() => setVisible(true)}
        ></i>

        <Dialog
          header="Change Password"
          visible={visible}
          className="col-10 col-sm-10 col-md-6 col-lg-6 col-xl-4 col-xxl-4"
          onHide={() => setVisible(false)}
        >
          <ChangePassword></ChangePassword>
        </Dialog>
      </div>
      <i
          title="Logout"
          className="pi pi-power-off"
          style={{ fontSize: "1.5rem", cursor: "pointer", color: "white" }}
          onClick={handleLogout}
        ></i>

    </div>
  );

  let isAuthenticated = false;
  const userCookie = Cookies.get("user");
  const cookieExpiration = Cookies.get("user");
  if (!cookieExpiration) {
    isAuthenticated = false;
  }
  else{
    isAuthenticated = true;
  }

  function ProtectedRoute({ component: Component, isAuthenticated, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? <Component {...props} /> : <Navigate to="/login" />
        }
      />
    );
  }

  function ProtectedRoute({ element }) {
    const isAuthenticated = userCookie ? true : false;
    return isAuthenticated ? element : <Navigate to="/login" />;
  }

  return (
    // <Router basename="/ProductCatalog">
    <Router>
      {loading ? (
        <Loader />
      ) : user ? (
        <div className="sticky-menubar">
          <Menubar model={items} start={start} end={end} />
        </div>
      ) : (
        <></>
      )}

      <Routes>
        <Route path="/">
          <Route index element={<ProductLocation />} />
          <Route path="/productlocation" element={<ProductLocation />} />
        </Route>
        <Route
          path="/productfilescl"
          element={<ProtectedRoute element={<ProductFilesCL />} />}
        />
        <Route
          path="/addFolders"
          element={<ProtectedRoute element={<AddFolders user={user} />} />}
        />
        {/* <Route path="/productfilescl" element={<ProductFilesCL />} /> */}
        <Route path="/login" element={<Login />} />
        {/* <Route path="/productlocation" element={<ProductLocation />}>
          <Route path="insta" element={<Insta />} />
          <Route path="mail" element={<Mail />} />
          <Route element={<Mail />} />
        </Route> */}
      </Routes>
    </Router>
  );
}
