import React, { useState, useEffect , useRef} from "react";
import axios from "axios";
import BASE_URLPaths from "../configBaseURL";
import LocationFolderList from "./LocationFolderList";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from 'primereact/toast';

const AddFolders = ({ user }) => {
  const [formData, setFormData] = useState({
    folderid: 0,
    foldername: "",
    IsActive: "",
    orderby: 1,
    selectedlocation: "",
    // Add more fields as needed
  });
  const [locations, setLocations] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const toastfolder = useRef(null);

  useEffect(() => {
    axios
      .get(`${BASE_URLPaths.BASE_URL}/api/GetLocationDetails`)
      .then((response) => {
        setLocations(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleDropdownChange = (event) => {
    setFormData({
      ...formData,
      selectedlocation: event.target.value,
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRadioChange = (event) => {
    setFormData({
      ...formData,
      IsActive: event.target.value,
    });
  };

  const handleSaveData = () => {
    if (validateForm()) {
      let locationFolder = {
        LocationID: parseInt(formData.selectedlocation),
        FolderName: formData.foldername,
        IsActive: formData.IsActive == "Active" ? true : false,
        OrderByNo: parseInt(formData.orderby),
        folderid: formData.folderid,
        CreatedBy: user.username,
      };

      axios
        .post(
          `${BASE_URLPaths.BASE_URL}/api/INSERTLocationFoldermst`,
          locationFolder
        )
        .then((response) => {
          setRefreshKey((prevKey) => prevKey + 1);
          if (formData.folderid != 0) {
            alert("Folder updated successfully");
          } else {
            alert("Folder saved successfully");
          }

          setFormData({
            ...formData,
            folderid: 0,
            foldername: "",
            IsActive: "",
            orderby: 1,
            selectedlocation: "",
          });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.selectedlocation) {
      newErrors.selectedlocation = "Select location";
      isValid = false;
    }

    if (!formData.foldername.trim()) {
      newErrors.foldername = "Folder Name is required";
      isValid = false;
    }

    if (!formData.IsActive) {
      newErrors.IsActive = "Select Active / Not Active";
      isValid = false;
    }

    if (!formData.orderby) {
      newErrors.orderby = "Order By No is required";
      isValid = false;
    }

    if (Object.keys(newErrors).length > 0) {
      // Show validation errors in an alert
      alert(
        "Please fix the following errors:\n\n" +
          Object.values(newErrors).join("\n")
      );
      return false;
    }
    return isValid;
  };

  const updateformData = (rowData) => {
    setFormData({
      ...formData,
      foldername: rowData.FolderName,
      IsActive: rowData.IsActive == true ? "Active" : "Not Active",
      selectedlocation: rowData.LocationID,
      orderby: rowData.OrderByNo,
      folderid: rowData.FolderID,
    });
  };

  const deleteformData = (rowData) => {
    let locationFolder = {
      folderid: rowData.FolderID,
    };

    axios
      .post(
        `${BASE_URLPaths.BASE_URL}/api/DeleteLocationFolder`,
        locationFolder
      )
      .then((response) => {
        setRefreshKey((prevKey) => prevKey + 1);
        toastfolder.current.show({ severity: 'info', summary: 'Confirmed', detail: 'Folder Removed Successfully', life: 5000 });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <>
    <Toast ref={toastfolder}  position="top-right" />
      <div class="row g-3 fs-6">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
          <label for="ddlLocation" class="form-label">
            Location
          </label>
          <select
            id="ddlLocation"
            class="form-select"
            value={formData.selectedlocation}
            onChange={handleDropdownChange}
          >
            <option value="">Select an option</option>
            {locations.map((item) => (
              <option key={item.AutoID} value={item.AutoID}>
                {item.LocationName}
              </option>
            ))}
          </select>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
          <label for="txtFolderName" class="form-label">
            Folder Name
          </label>
          <input
            type="text"
            class="form-control border"
            style={{ border: "1" }}
            id="txtFolderName"
            name="foldername"
            value={formData.foldername}
            onChange={handleInputChange}
          />
        </div>
        <div class="col-md-6">
          <div class="form-check">
            <input
              class="form-check-input border"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              value="Active"
              checked={formData.IsActive === "Active"}
              onChange={handleRadioChange}
            />
            <label class="form-check-label" for="flexRadioDefault1">
              Active
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input border"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              value="Not Active"
              checked={formData.IsActive === "Not Active"}
              onChange={handleRadioChange}
            />
            <label class="form-check-label" for="flexRadioDefault2">
              Not Active
            </label>
          </div>
        </div>
        <div class="col-md-6">
          <label for="txtOrderBy" class="form-label">
            Order By
          </label>
          <input
            type="number"
            class="form-control"
            min={0}
            value={formData.orderby}
            name="orderby"
            onChange={handleInputChange}
            id="txtOrderBy"
          />
        </div>
        <div class="col-12 text-center">
          <button onClick={handleSaveData} class="btn btn-primary">
            Save
          </button>
        </div>
        <div>
          <LocationFolderList
            onupdateformData={updateformData}
            ondeleteformData={deleteformData}
            refreshKey={refreshKey}
          ></LocationFolderList>
        </div>
      </div>
    </>
  );
};

export default AddFolders;
