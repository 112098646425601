import React, { useState, useContext } from "react";
import { Button } from "primereact/button";
import axios from "axios";
import BASE_URLPaths from "../configBaseURL";
import { InputText } from "primereact/inputtext";
import { AppState } from "../App";

const ChangePassword = () => {
  const userData = useContext(AppState);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const handleChangePassword = (e) => {
    e.preventDefault();
    if (newPassword == "") {
      alert("Enter New Password");
      return false;
    }
    if (confirmNewPassword == "") {
        alert("Enter New confirm New Password");
        return false;
      }
    if (newPassword === confirmNewPassword) {
      Change_Password();
      console.log("Password change request submitted");
      // Reset form fields
      setNewPassword("");
      setConfirmNewPassword("");
    } else {
        alert("New passwords do not match");
    }
  };

  const userCreds = {
    username: userData.username,
    newpass: newPassword,
  };

  const Change_Password = () => {
    axios
      .post(`${BASE_URLPaths.BASE_URL}/api/ChangePassword`, userCreds)
      .then((response) => {
        alert("Password has been changed for " + userCreds.username);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <form onSubmit={handleChangePassword}>
        <InputText
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="New Password"
        />
        <br />
        <InputText
          type="password"
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          placeholder="Confirm New Password"
        />
        <br />
        <Button
          label="Change Password"
          icon="pi pi-user"
          className="w-10rem mx-auto custom-button"
          type="submit"
        />
      </form>
    </div>
  );
};

export default ChangePassword;
