
const BASE_URLPaths = {
    //BASE_URL :"http://localhost:3005", //Local 
    //BASE_URL :"http://192.168.1.7:81", //IIS Website Node Js API
    //BASE_URL :"http://localhost:81", //IIS Website Node Js API
    //BASE_URL :"http://192.168.1.20:3007", //IIS Application Node Js API
    //ImagePath_URL :"http://192.168.1.20/MyWorkSpace", //Local 
    BASE_URL :"https://productcatelogapi.liquigasindia.com"
}

export default BASE_URLPaths;