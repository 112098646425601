import React, { useState, useEffect } from "react";
import axios from "axios";
import BASE_URLPaths from "../configBaseURL";
import { useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Carousel } from "primereact/carousel";
import { Tag } from "primereact/tag";
import { Dialog } from "primereact/dialog";
import Loader from "./Loader";
import { Image } from 'primereact/image';
        

const ProductFilesCL = () => {
  const location = useLocation();
  //console.log(location);
  const subDirPath = location.state.subDirPath;
  const folder = location.state.folder;
  const baseFolderName = location.state.baseFolderName;
  const BaseLocationURL = location.state.BaseLocationURL;
  console.log(BaseLocationURL);
  const BaseConnectURLDir = location.state.BaseConnectURLDir;

  const finalURL =
    BaseLocationURL +
    (BaseConnectURLDir != null ? BaseConnectURLDir : "") +
    baseFolderName;

  const imgmodalState = {
    visible: false,
    imgmodalfinalURL: "",
  };
  const [objimgmodalstate, setobjimgmodalstate] = useState(imgmodalState);
  const [customers, setCustomers] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const postData = {
    path: subDirPath,
  };

  const GetAllFiles = () => {
    axios
      .post(`${BASE_URLPaths.BASE_URL}/api/getFileDetails`, postData)
      .then((response) => {
        //setTimeout(() => {
          setFiles(response.data);
          console.log(response.data);
          setLoading(false);
        //}, 2000); // Simulate a 2-second delay

        
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetAllFiles();
  }, []);

  const folderTemplate = (files) => {
    return (
      <>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-center default-font poppins-font">
            {files.fileextension != null ? folder + " - " + files.filenamewoext : ""}
          </div>
        </div>
        <div className="border-1 surface-border border-round m-1 text-center centered-content">
          {/* <h6>{files.basefilename}</h6> */}
          {files.fileextension != null ? (
            files.fileextension.toLowerCase() == ".mp4" ? (
              <>
                <div>
                  <video width="100%" height="450px" controls>
                    <source
                      src={`${finalURL}/${folder}/${files.basefilename}`}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </>
            ) : (
              <>
                <div className="col-11 col-sm-6 col-md-6 col-lg-5 col-xl-5 col-xxl-3 text-center">
                <Image src={`${finalURL}/${folder}/${files.basefilename}`} alt={files.basefilename} width="270" style={{ cursor: "pointer" }} preview />
                  {/* <img
                    src={`${finalURL}/${folder}/${files.basefilename}`}
                    alt={files.basefilename}
                    style={{ cursor: "pointer" }}
                    height="450px"
                    width="100%"
                    onClick={() =>
                      setobjimgmodalstate({
                        visible: true,
                        imgmodalfinalURL: `${finalURL}/${folder}/${files.basefilename}`,
                      })
                    }
                  /> */}
                </div>
              </>
            )
          ) : (
            <></>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : files.length ? (
        <div className="card">
          <Carousel
            value={files}
            numScroll={1}
            numVisible={1}
            itemTemplate={folderTemplate}
            className="p-carousel-content"
          />
          {/* <Dialog
            visible={objimgmodalstate.visible}
            style={{ width: "90%", height: "100%" }}
            onHide={() =>
              setobjimgmodalstate({
                visible: false,
                imgmodalfinalURL: "",
              })
            }
          >
            <div style={{ textAlign: "center" }}>
              <img
                src={objimgmodalstate.imgmodalfinalURL}
                alt={files.basefilename}
                style={{ height: "700px" }}
              />
            </div>
          </Dialog> */}
        </div>
      ) : (
        <div className="text-center poppins-font default-font">No Files Found!</div>
      )}
    </>
  );
};

export default ProductFilesCL;
