import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "./Login.css";
import productlogo from "./images/ProductLogo.png";
import { Dialog } from "primereact/dialog";
import { Link } from "react-router-dom";
import ForgotPassword from "./components/ForgotPassword";

const Login = ({ handleLogin, user }) => {
  const [username, setusername] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(username, password);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    handleLogin(username, password);
  };

  return (
    <div className="back loginbody">
      <div className="div-center">
        <div className="content">
          <div className="row">
            {/* <div className="col-md-4">
              <img src={productlogo} className="productappLogo" />
            </div> */}
            <div className="col-md-12 text-center">
              <h3 style={{ display: "inline" }}>PRODUCT CATALOG</h3>
            </div>
          </div>
          <hr style={{ marginTop: "3%" }}></hr>
          <br />
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <InputText
                id="Username"
                type="text"
                onChange={(e) => setusername(e.target.value)}
                placeholder="Username"
              />
            </div>
            <br />
            <div className="form-group">
              <InputText
                id="Password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
            </div>
            <br />
            <Button
              label="Login"
              icon="pi pi-user"
              className="w-10rem mx-auto custom-button"
            />
          </form>
          <div>
            <a style={{ cursor: "pointer",color:"blue" }} onClick={() => setVisible(true)}>
              Forgot Password
            </a>
            <Dialog
              header="Forgot Password"
              visible={visible}
              className="col-10 col-sm-10 col-md-6 col-lg-6 col-xl-4 col-xxl-4"
              onHide={() => setVisible(false)}
            >
              <ForgotPassword></ForgotPassword>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
