import React, { useState } from 'react';
import { Button } from "primereact/button";
import axios from "axios";
import BASE_URLPaths from "../configBaseURL";
import { InputText } from "primereact/inputtext";

const ForgotPassword = () => {
  const [uname, setuname] = useState('');

  const handleunameChange = (e) => {
    setuname(e.target.value);
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    SendMailForgotPassword();
    console.log('Password reset request for uname:', uname);
  };

  const userCreds = {
    username: uname,
  };

  const SendMailForgotPassword = () => {
    axios
      .post(`${BASE_URLPaths.BASE_URL}/api/SendMailForgotPass`, userCreds)
      .then((response) => {
        console.log('Mail Sent:', uname);
        alert("Forgot Password Mail Sent for " + userCreds.username);
      })
      .catch((err) => {
        console.log(err);
      });
  };

//   useEffect(() => {
//     GetFolders();
//   }, []);

  return ( 
  <div>
      <form onSubmit={handlePasswordSubmit}>
        <div className="row">
          <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8 text-center default-font poppins-font">
          <InputText
                id="txtUname"
                type="text"
                onChange={handleunameChange}
                placeholder="Username"
              />
    
          </div>
          <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-center default-font poppins-font">
          <Button label="Login"
              icon="pi pi-user forgotbutton"
              className="w-3rem mx-auto customfg-button" type="submit"></Button>
          </div>
        </div>
        
       
      </form>
    </div>
  );
};

export default ForgotPassword;
