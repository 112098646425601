import React, { useEffect, useState } from "react";
import axios from "axios";
import BASE_URLPaths from "../configBaseURL";
import { Card } from "primereact/card";
import { Link, Outlet } from "react-router-dom";
import { Image } from "primereact/image";
import Loader from "./Loader";

const FolderTiles = (props) => {
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(true);
  // const postData = {
  //   path: props.location.LocationPath,
  // };

  // const GetAllFolders = () => {
  //   axios.post(`${BASE_URLPaths.BASE_URL}/api/listDirectories`, postData).then((response) => {
  //     setFolders(response.data);
  //   });
  // };

  // useEffect(() => {
  //   GetAllFolders();
  // }, []);

  const postuserLocation = {
    LocationID: props.location.AutoID,
  };

  const GetFolders = () => {
    axios
      .post(
        `${BASE_URLPaths.BASE_URL}/api/GetLocationFolders`,
        postuserLocation
      )
      .then((response) => {
        setFolders(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetFolders();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <div className="row">
            {folders.map((folder, i) => {
              let subDirPath = props.location.LocationPath + folder.FolderName;
              let baseFolderName = props.location.FolderPath;
              let BaseLocationURL = props.location.BaseLocationURL;
              let BaseConnectURLDir = props.location.BaseConnectURLDir;
              return (
                <Card
                  className="col-5 col-sm-5 col-md-3 col-lg-2 col-xl-1 col-xxl-1"
                  style={{
                    color: "maroon",
                    backgroundColor: "white",
                    margin: "5px",
                    textAlign: "center",
                  }}
                >
                  <Link
                    to="productfilescl"
                    state={{
                      subDirPath,
                      folder: folder.FolderName,
                      baseFolderName,
                      BaseLocationURL,
                      BaseConnectURLDir,
                    }}
                    className="link-no-underline"
                  >
                    <div className="flex justify-content-center">
                      <Image
                        src="/images/yellowFolder.png"
                        alt="Image"
                        width="80"
                      />
                    </div>
                    <div 
                    // style={{
                    //     marginTop: "-50%"
                    //   }}
                      >
                    <p className="m-0 default-font poppins-font">
                      {folder.FolderName}
                    </p>
                    </div>
                    </Link>
                </Card>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default FolderTiles;
