import { PrimeReactProvider } from "primereact/api";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
//import "primereact/resources/themes/soho-light/theme.css";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import Navbar from "./components/Navbar";
import "./App.css";
import "./Product.css";
import "bootstrap/dist/css/bootstrap.min.css";
import bootstrap from "bootstrap/dist/js/bootstrap";
import Cookies from "js-cookie";
import axios from "axios";
import Login from "./Login";
import { useEffect, useState, createContext } from "react";
import BASE_URLPaths from "./configBaseURL";

const AppState = createContext();

function App() {
  const value = {
    appendTo: "self",
  };

  let obj = {};

  const [expireTime, setExpireTime] = useState(15);

  const [user, setUser] = useState(null);


  useEffect(() => {
    const userCookie = Cookies.get("user");
    if (userCookie) {
      setUser(JSON.parse(userCookie));
    }

    const handleUserActivity = () => {
      const cookieExpiration = Cookies.get("user");
      if (!cookieExpiration) {
        handleLogout();
      } else {
        const currentTime = new Date();
        const expirationTime = new Date(
          currentTime.getTime() + expireTime * 60 * 1000
        );
        Cookies.set("user", JSON.stringify(obj), {
          expires: expirationTime,
        });
      }
    };

    window.addEventListener('click',handleUserActivity);
    return()=>{
      window.removeEventListener('click',handleUserActivity);
    }
  }, []);

  const handleLogin = (username, Password) => {
    if (username == "" || Password == "") {
      return alert("Please enter valid input.");
    }
    const userCreds = {
      UserName: username,
      Password: Password,
    };

    axios
      .post(`${BASE_URLPaths.BASE_URL}/api/CheckUserLogin`, userCreds)
      .then((response) => {
        if (response.data[0].IsLoginSuccess == true) {
          const user = {
            username,
            isAvailable: "Available",
            ImageName: response.data[0].ImageName,
            IsAdmin:response.data[0].IsAdmin
          };
          //alert("Test 1 "+ user);
          setUser(user);
          obj = {user}
          const currentTime = new Date();
          const expirationTime = new Date(
            //currentTime.getTime() + (15 * 60 * 10000000)
            currentTime.getTime() + 150 * 60 * 100
          );
          Cookies.set("user", JSON.stringify(user), {
            expires: expirationTime,
          });
        } else {
          setUser(null);
          Cookies.remove("user");
          alert(response.data[0].Message);
          return;
        }
      })
      .catch((err) => {
        console.log("Error Fetching DB Check Login :", err);
        //alert(err)
      });
  };

  const handleLogout = () => {
    setUser(null);
    Cookies.remove("user");
  };

  return (
    <PrimeReactProvider value={value}>
      <div>
        {user ? (
          <AppState.Provider value={user}>
            <div>
              <Navbar user={user} handleLogout={handleLogout} />
            </div>
          </AppState.Provider>
        ) : (
          <Login handleLogin={handleLogin}></Login>
        )}
      </div>
    </PrimeReactProvider>
  );
}

export default App;
export { AppState };
